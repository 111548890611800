import { mapGetters, mapMutations } from "vuex";
import { getProductPriceTitle, resolveProductImage, routeToProduct } from "@/helpers";

export default {
    name: "basket-item",
    props: {
        basketHasPromotion: {
            type: Boolean,
            default: false,
        },
        product: {
            type: Object,
            default: () => ({}),
        },
    },
    data() {
        return {
            selectedCount: 1,
            width: window.innerWidth,
        };
    },
    computed: {
        ...mapGetters({
            basket: "basket/basket",
            user: "profile/user",
            isDistributor: "profile/isDistributor",
        }),
        hasPromotion() {
            return this.product.select_type.hasActivePromotion || this.product.select_type.hasIndividualDiscount;
        },
        currentProductPrice() {
            return this.product.select_type.hasActivePromotion || this.product.select_type.hasIndividualDiscount
                ? this.product.select_type.oldPrice
                : this.product.select_type.price;
        },
    },
    methods: {
        getProductPriceTitle,
        routeToProduct,
        resolveProductImage,
        ...mapMutations({
            increment: "basket/INCREMENT_BASKET_LIST_COUNT",
            decrement: "basket/DECREMENT_BASKET_LIST_COUNT",
            setCount: "basket/SET_BASKET_LIST_COUNT",
            remove: "basket/REMOVE_ITEM_FROM_BASKET",
        }),
        changeCount(type, count = 1) {
            if (type === "increment") {
                this.increment(this.product);
            } else if (type === "decrement") {
                this.decrement(this.product);
            } else {
                if (count) {
                    this.setCount({
                        product: this.product,
                        select_count: parseInt(count),
                    });
                }
            }
        },
        updateWidth() {
            this.width = window.innerWidth;
        },
    },
    mounted() {
        window.addEventListener('resize', this.updateWidth);
    },
    beforeDestroy() {
        window.removeEventListener('resize', this.updateWidth);
    },
};
